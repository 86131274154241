<template>
  <div>
    <div v-if="$userInfo.isSupportUser || $userInfo.isCustomerAdministrator"
      class="tile is-parent pl-1">
      <article class="tile is-child box">
        <div class="columns is-flex is-align-items-center">
          <div class="column">
            <p class="title">Integration</p>
          </div>
          <div v-if="!hasError && showSavingStatus && !toastNotificationEnabled"
            class="column is-flex is-justify-content-flex-end is-align-items-center">
            <span v-if="saving"
              class="button is-loading is-white"
              style="height: 27px;" />
            <span v-else
              class="text has-text-success">
              <i class="mdi mdi-checkbox-marked-circle-outline mdi-18px" />
            </span>
            <span class="is-size-6"
              :class="saving ? 'has-text-warning' : 'has-text-success'">{{ savingStatus }}</span>
          </div>
        </div>

        <div class="field">
          <div class="tabs is-boxed">
            <ul>
              <li v-for="(companyExternalSetting, index) in companyExternalSettings"
                :key="index"
                :class="{ 'is-active' : activeTabIndex === index }">
                <a @click="activeTabIndex = index">{{ companyExternalSetting.settingName }}</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="field columns"
          v-if="activeCompanyExternalSetting">
          <div class="column is-half">
            <div class="field"
              v-for="(column, index) in $options.columns.left"
              :key="index">
              <label class="label">{{ activeCompanyExternalSetting[column.label] }}</label>
              <div class="field has-addons">
                <div class="control is-expanded">
                  <input class="input"
                    type="text"
                    v-model="activeCompanyExternalSetting[column.value]"
                    :disabled="requirePasswordGeneration(column)">
                </div>

                <p v-if="requirePasswordGeneration(column)"
                  class="control">
                  <button class="button is-primary"
                    @click="randomPassword(32, 6)">
                    Generate
                  </button>
                </p>
              </div>
            </div>
          </div>

          <div class="column is-half">
            <div class="field"
              v-for="(column, index) in $options.columns.right"
              :key="index">
              <label class="label">{{ activeCompanyExternalSetting[column.label] }}</label>
              <input class="input"
                type="text"
                v-model="activeCompanyExternalSetting[column.value]">
            </div>
          </div>
        </div>
      </article>
    </div>
    <div v-else
      class="is-flex is-justify-content-center pt-5 ">
      <div class="notification is-danger is-flex is-justify-content-center is-size-4"
        style="width: 50%">
        You do not have permissions on this page
      </div>
    </div>
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import _debounce from 'lodash/debounce'
import StoreMixin from './storeMixin'
import { DeepDiff } from 'deep-diff'

export default {
  name: 'CompanyExternalSetting',
  mixins: [StoreMixin],
  data() {
    return {
      autoUpdate: null,
      companyExternalSettings: null,
      activeTabIndex: 0
    }
  },
  columns: {
    left: [
      { label: 'tagLabel', value: 'tag' },
      { label: 'usernameLabel', value: 'username' },
      { label: 'passwordLabel', value: 'password' },
      { label: 'servernameLabel', value: 'serverName' },
      { label: 'portLabel', value: 'port' }
    ],
    right: [
      { label: 'property1Label', value: 'property1' },
      { label: 'property2Label', value: 'property2' },
      { label: 'property3Label', value: 'property3' },
      { label: 'property4Label', value: 'property4' },
      { label: 'property5Label', value: 'property5' }
    ]
  },
  computed: {
    activeCompanyExternalSetting() {
      if (this.companyExternalSettings && this.companyExternalSettings[this.activeTabIndex]) {
        return this.companyExternalSettings[this.activeTabIndex]
      }
      return null
    }
  },
  watch: {
    companyExternalSettings: {
      handler: function(val) {
        this.saveSnapshot({ entity: _cloneDeep(val) })
        this.autoUpdate(val)
      },
      deep: true
    }
  },
  async created() {
    this.$showSpinner()
    this.initializeAutoUpdate()
    this.companyExternalSettings =
      _cloneDeep(await this.getStoreItem({ serviceFunction: 'getCompanyExternalSettings' }))
    this.$hideSpinner()
  },
  methods: {
    disableAutoUpdate() {
      if (typeof this.autoUpdate === 'function'
        && typeof this.autoUpdate.cancel === 'function') {
        this.autoUpdate.cancel()
      }
      this.autoUpdate = () => {}
    },
    initializeAutoUpdate() {
      this.autoUpdate = _debounce(async (value) => {
        this.snapshotDiff && await this.updateExternalSetting(value)
      }, this.debounceDelay)
    },
    randomPassword(length, nonAlphaLength) {
      var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOP1234567890'
      var nonAlphaChars = '!@#$%^*()_+~|}{[]\:;?,./-='
      var pass = ''
      for (var x = 0; x < length; x++) {
        var i = Math.floor(Math.random() * chars.length)
        pass += chars.charAt(i)
      }
      for (var y = 0; y < nonAlphaLength; y++) {
        var n = Math.floor(Math.random() * nonAlphaChars.length)
        var pos = Math.floor(Math.random() * pass.length)
        pass = pass.substring(0, pos) + nonAlphaChars.charAt(n) + pass.substring(pos + nonAlphaChars.charAt(n).length)
      }
      this.activeCompanyExternalSetting['password'] = pass
    },
    requirePasswordGeneration(column) {
      const integrations = ['BodyNet', 'WebTrim', 'Planning Plus']
      return column.value == 'password' && integrations.some(i => i == this.activeCompanyExternalSetting['settingName'])
    },
    async updateExternalSetting(externalSetting) {
      if (!this.snapshotDiff) return
      for (let item of externalSetting) {
        const [baseItem] = this.baseSnapshot.filter(i => i.companyExternalSettingId == item.companyExternalSettingId)
        const diff = DeepDiff.diff(baseItem, item)
        item.deepDiff = diff ? JSON.stringify(diff) : null
      }
      this.companyExternalSettings = _cloneDeep(await this.editStoreItem({
        serviceFunction: 'updateCompanyExternalSetting', entity: externalSetting }))

      if (this.toastNotificationEnabled) {
        this.openToastNotification()
      }
    }
  },
  async beforeRouteLeave(to, from, next) {
    this.disableAutoUpdate()
    this.toastNotificationEnabled = true
    await this.updateExternalSetting(this.companyExternalSettings)
    this.clearSnapshots()
    this.toastNotificationEnabled = false
    next()
  }
}
</script>

<style lang="scss" scoped>
.tabs ul{
  width: 10em;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: #71b3ff;
  border-radius: 10px;

  &:hover {
    background: #3291EF;
  }
}
</style>